// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ResourcesConfig } from 'aws-amplify';

const stage = 'gamma';
const region = 'FE'.toLowerCase();
const applicationUrl =
  stage.toLowerCase() !== 'prod'
    ? `https://${region}.${stage}.audits-procurement.amazon.dev`
    : `https://${region}.audits-procurement.amazon.dev`;

export const getCognitoConfig = (): ResourcesConfig => {
  return {
    Auth: {
      Cognito: {
        userPoolId: 'us-west-2_hxjU09kIx',
        userPoolClientId: '76gjamho3gfbmf85u7a329ujq6',
        signUpVerificationMethod: 'code',
        loginWith: {
          oauth: {
            domain: 'global-auditing-tool-gamma.auth.us-west-2.amazoncognito.com',
            scopes: [
              'email',
              'profile',
              'openid',
              'aws.cognito.signin.user.admin',
            ],
            redirectSignIn: [
              'http://localhost:4321/',
              'http://localhost:8080/',
              applicationUrl,
            ],
            redirectSignOut: [
              'http://localhost:4321/',
              'http://localhost:8080/',
              applicationUrl,
            ],
            responseType: 'code',
          },
        },
      },
    },
  };
};
